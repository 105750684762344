import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { BusinessAreaDataResponse } from '../interfaces/Admin/BusinessReporting/business-reporting-request-response';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { AddUserRequest, ClientAddUserRequest } from '../interfaces/Admin/User/AddUserRequest';
import { AvailabilityStatusListResponse } from '../interfaces/Admin/User/AvailabilityStatusListResponse';
import { UserListRequest } from '../interfaces/Admin/User/UserListRequest';
import { UserListResponse } from '../interfaces/Admin/User/UserListResponse';
import { UserLookupListResponse } from '../interfaces/Admin/User/UserLookupList';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _apiService: ApiService) {}

  getUserList(userReqBody: UserListRequest): Observable<UserListResponse> {
    return this._apiService.post<UserListResponse>(APIUrls.GetUserListUserCon, { ...userReqBody });
  }

  addUser(addUserReq: AddUserRequest | ClientAddUserRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.AddUser, { ...addUserReq });
  }

  editUser(addUserReq: AddUserRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.EditUser, { ...addUserReq });
  }

  deleteUser(id: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(`${APIUrls.DeleteUser}/${id}`, {});
  }

  getUserProfileImage(id: number) {
    return this._apiService.get(`${APIUrls.GetUserProfileImage}/${id}`, null, { responseType: 'blob' });
  }

  // availability status
  getAvailibilityStatus(): Observable<AvailabilityStatusListResponse> {
    return this._apiService.get<AvailabilityStatusListResponse>(APIUrls.GetAvailibilityStatus);
  }

  updateAvailabilityStatus(id: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(`${APIUrls.UpdateAvailabilityStatus}/${id}`, {});
  }

  getUserLookupList(): Observable<UserLookupListResponse> {
    return this._apiService.get<UserLookupListResponse>(APIUrls.GetUserLookupList);
  }

  getHJLegalUserList(): Observable<UserLookupListResponse> {
    return this._apiService.get<UserLookupListResponse>(APIUrls.GetLegelUserLookupList);
  }

  getBusinessReportAreas(): Observable<BusinessAreaDataResponse> {
    return this._apiService.get<BusinessAreaDataResponse>(APIUrls.GetBusinessArea);
  }

  checkServerAvailability(): Observable<object> {
    return this._apiService.get(APIUrls.CheckServerAvailability);
  }
}
