import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { AddUserRequest } from '../interfaces/Admin/User/AddUserRequest';
import { ForgotPasswordResponse } from '../interfaces/Auth/ForgotPasswordResponse';
import { LoginResponse } from '../interfaces/Auth/LoginResponse';
import { OTPResponse } from '../interfaces/Auth/OTPResponse';
import { UserDetailsResponse } from '../interfaces/Auth/UserDetails';
import { PracticeArea, User, UserDetailsByUserIdResponse } from '../interfaces/Auth/UserDetailsByUserIdResponse';
import { ClientLoginResponse } from '../interfaces/Client/ClientLoginResponse';
import { ExistingClientCheckResponse } from '../interfaces/Client/ExistingClientCheckResponse';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private userNameSubject = new BehaviorSubject<string>('');
  userNameObservable: Observable<string> = this.userNameSubject.asObservable();
  private ProfilePictureSubject = new BehaviorSubject<string>('');
  profilePictureObservable: Observable<string> = this.ProfilePictureSubject.asObservable();

  private userPracticeAreaHeadDataSubject = new BehaviorSubject<PracticeArea[]>([]);
  userPracticeAreaHeadDataObservable: Observable<PracticeArea[]> = this.userPracticeAreaHeadDataSubject.asObservable();

  currentUser = signal<User | null>(null);

  setUpdatedData(key: string, data: string) {
    if (key == 'userName') this.userNameSubject.next(data);
    else this.ProfilePictureSubject.next(data);
  }

  constructor(private _apiService: ApiService) {}

  encryptPassword(password: string) {
    return btoa(password);
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this._apiService.post<LoginResponse>(APIUrls.Login, { email, password });
  }

  setPracticeAreaData(practiceArea: PracticeArea[] | null) {
    this.userPracticeAreaHeadDataSubject.next(practiceArea?.length ? practiceArea : []);
  }

  otpVerification(otp: number, userId: number): Observable<OTPResponse> {
    return this._apiService.post<OTPResponse>(APIUrls.OTPVerify, { otp, userId });
  }

  resendOtp(email: string): Observable<SuccessResponse> {
    return this._apiService.get<SuccessResponse>(`${APIUrls.ResendOTP}/${email}`);
  }

  forgotPassword(email: string): Observable<ForgotPasswordResponse> {
    return this._apiService.get<ForgotPasswordResponse>(APIUrls.ForgotPassword, { emailId: email });
  }

  resetPassword(password: string, confirmPassword: string, userId: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.ResetPassword, { password, confirmPassword, userId });
  }

  uploadProfilePhoto(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this._apiService.post(APIUrls.UploadProfileImage, formData);
  }

  completeProfile(addUserReq: AddUserRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.Profile, { ...addUserReq });
  }

  getUserByID(id: number): Observable<UserDetailsByUserIdResponse> {
    if (this.currentUser() && this.currentUser()!.id == id) {
      return of({
        result: this.currentUser(),
        status: true,
        message: 'User details retrieved (cache)',
        statusCode: 200,
      } as UserDetailsByUserIdResponse);
    }

    return this._apiService.get<UserDetailsByUserIdResponse>(`${APIUrls.GetUserDetailsByUserId}/${id}`).pipe(
      tap((res) => {
        if (res.status && res.result) {
          this.currentUser.set({ id: id, ...res.result });
        }
      }),
    );
  }

  confirmEmail(userId: number, email: string, token: string): Observable<SuccessResponse> {
    return this._apiService.postWithBackend<SuccessResponse>(APIUrls.ConfirmEmail, { userId, email, token });
  }

  clientLogin(emailId: string, password: string, username: string, isSignUp: boolean): Observable<ClientLoginResponse> {
    return this._apiService.post<ClientLoginResponse>(APIUrls.EndClientLoginOrSignUp, {
      emailId,
      password,
      username,
      isSignUp,
    });
  }

  checkIfExistingClient(emailID: string): Observable<ExistingClientCheckResponse> {
    return this._apiService.post<ExistingClientCheckResponse>(
      `${APIUrls.CheckForExistingClient}?emailId=${emailID}`,
      {},
    );
  }

  getUserByEmailID(emailID: string): Observable<UserDetailsResponse> {
    return this._apiService.get<UserDetailsResponse>(`${APIUrls.GetUserDetailsByEmail}/${emailID}`);
  }

  twoFactorAuthentication(userId: number, twoFactorAuthenticationValue: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.TwoFactorAuthentication, {
      userId,
      twoFactorAuthenticationValue,
    });
  }
}
